import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.scss";

import {productsSlider, title, subtitle, productsWrapper, arrows, imageContainer, category, brand, button } from './products-slider.module.scss';

import {ISliderProduct} from "../../../../models/slider-product.model";
import {useI18next} from "../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import useSwiperSettings from "../../../../hooks/home/banner-section/use-swiper-settings";

import Section from "../../../molecules/section";
import SectionWrapper from "../../../molecules/section-wrapper";
import SliderArrows from "../../../molecules/slider/slider-arrows";

interface IProductsSliderProps {
    products: ISliderProduct[];
}

const ProductsSlider: React.FC<IProductsSliderProps> = ({products}) => {

    const { t } = useI18next();
    const { arrowsRef, settings } = useSwiperSettings();

    return (
        <SectionWrapper bgColor="white" className={productsSlider}>
            <Section
                title={{
                    Tag: "h2",
                    children: t("home.productsSlider.title"),
                    subtitle: t("home.productsSlider.subtitle"),
                    size: "medium",
                    className: title,
                    subtitleClassName: subtitle,
                }}
            />
            <Section className={productsWrapper}>
                <Swiper
                    {...settings}
                    watchSlidesVisibility={true}
                    loop={true}
                    slidesPerView={4}
                    spaceBetween={20}
                    breakpoints={
                    {
                        0: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                        },
                        421: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        571: {
                            slidesPerView: 3,
                        },
                        1392: {
                            slidesPerView: 4,
                        }
                    }
                }
                >
                    {products.map((product) => (
                        <SwiperSlide key={`productSlide-${product.productId}`}>
                            <div className={imageContainer}>
                                <img src={product.imgUrl} alt={product.category} />
                            </div>
                            <p className={category}>{product.category ? product.category : '\u00A0'}</p>
                            <p className={brand}>{product.brandName ? product.brandName : '\u00A0'}</p>
                            <a href={product.affiliateUrl} className={button} target={'_blank'}>{t("home.productsSlider.button")}</a>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <SliderArrows ref={arrowsRef} areArrowsSpread={true} className={arrows} />
            </Section>
        </SectionWrapper>
    )
}

export default ProductsSlider